import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const TeamQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        email
        degree
        position
        experience
        education
        date(formatString: "MMMM DD, YYYY")
        coverImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        profileImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`

const Team = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <SEO title={post.frontmatter.name} />
      <section id="slider" className="hero p-0 odd featured mt-5 mt-lg-0">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
          <div className="swiper-wrapper">
            <BackgroundImage
              Tag="section"
              className="swiper-slide slide-center post-hero"
              fluid={post.frontmatter.coverImage.childImageSharp.fluid}
            >
              <div className="slide-content row text-center">
                <div className="col-12 mx-auto inner">
                  <nav
                    data-aos="zoom-out-up"
                    data-aos-delay="800"
                    aria-label="breadcrumb"
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/about#team">Team</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {post.frontmatter.name}
                      </li>
                    </ol>
                  </nav>
                  <h1 className="mb-0 title effect-static-text">
                    {post.frontmatter.name}
                  </h1>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Img
                    fluid={post.frontmatter.profileImage.childImageSharp.fluid}
                    alt={post.frontmatter.name}
                  />
                  <p className="text-muted small-text font-weight-bold my-3">
                    {post.frontmatter.email === "" ? (
                      ""
                    ) : (
                      <>
                        <i className="fas fa-envelope mr-2"></i>
                        {post.frontmatter.email}
                      </>
                    )}
                  </p>
                  <p className="small-text my-1">
                    <strong>Position:</strong> {post.frontmatter.position}
                  </p>
                  <p className="small-text my-1">
                    <strong>Professional Experience:</strong>{" "}
                    {post.frontmatter.experience}
                  </p>
                  <p className="small-text my-1">
                    <strong>Education:</strong> {post.frontmatter.education}
                  </p>
                </div>
                <div className="col-md-8">
                  <h3 className="my-0">
                    {post.frontmatter.name}
                    {post.frontmatter.degree === ""
                      ? ""
                      : `, ${post.frontmatter.degree}`}
                  </h3>
                  <p className="text-uppercase text-primary font-weight-bold my-2">
                    {post.frontmatter.position}
                  </p>
                  <hr className="my-3" />
                  <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Team
